import react from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <Grid container sx={{ backgroundColor: "#ffc93c" }}>
      <Grid
        container
        sx={{
          flexDirection: {
            xs: "row",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          },
          minHeight: "9vh",
          position: "relative",
          backgroundColor: "#ffc93c",
          bottom: 0,
          left: 0,
          marginLeft: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: 5,
            xl: 5,
          },
          padding: {
            xs: 3,
            sm: 3,
            md: 3,
            lg: 0,
            xl: 0,
          },
          paddingBottom: 1,
          textAlign: "left",
        }}
        alignItems={{
          xs: "center",
          sm: "center",
          md: "center",
          lg: "center",
          xl: "center",
        }}
        // alignItems="center"
      >
        <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>
          <Typography
            variant="caption"
            onClick={() => navigate("/terms")}
            sx={{
              "&:hover": {
                color: "white",
                filter: "brightness(0)",
              },
              cursor: "pointer",
              fontSize: 14,
              textAlign: "left",
            }}
            fontWeight={500}
          >
            Terms and conditions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>
          <Typography
            variant="caption"
            onClick={() => navigate("/cookies")}
            sx={{
              "&:hover": {
                color: "white",
                filter: "brightness(0)",
              },
              cursor: "pointer",
              fontSize: 14,
            }}
            fontWeight={500}
          >
            Cookie Policy
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>
          <Typography
            variant="caption"
            sx={{
              "&:hover": {
                color: "white",
                filter: "brightness(0)",
              },
              cursor: "pointer",
              fontSize: 14,
            }}
            fontWeight={400}
          >
            Privacy Policy
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>
          <Typography
            onClick={() => navigate("/contact")}
            variant="caption"
            sx={{
              "&:hover": {
                color: "white",
                filter: "brightness(0)",
              },
              cursor: "pointer",
              fontSize: 14,
            }}
            fontWeight={400}
          >
            Contact us
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1} xl={2}>
          <Typography
            onClick={() => navigate("/what-do-we-do")}
            variant="caption"
            sx={{
              "&:hover": {
                color: "white",
                filter: "brightness(0)",
              },
              cursor: "pointer",
              fontSize: 14,
            }}
            fontWeight={400}
          >
            Our mission
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          margin: { sx: "20px" },
          display: "flex",
          justifyContent: {
            xl: "right",
            lg: "right",
            md: "right",
            sm: "right",
            xs: "right",
          },
        }}
      >
        <Typography
          variant="caption"
          sx={{
            fontSize: 12,
            padding: {
              xl: "2",
              lg: "2",
              md: "2",
              sm: "1",
              xs: "1",
            },
          }}
          fontWeight={300}
        >
          Copyright © 2024 <b>AAG Software</b> All rights reserved.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
