import react from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ContactPageIcon from "@mui/icons-material/ContactPage";

const ContactUs = () => {
  return (
    <Grid container>
      <Grid item xs={2} />
      <Grid item xs={8}>
        <Card
          sx={{
            border: "solid 3px black",
            backgroundColor: "white",
            marginTop: {
              xs: "15vh",
              sm: "15vh",
              md: "30vh",
              lg: "30vh",
              xl: "20vh",
            },
            padding: "2vh",
          }}
        >
          <ContactPageIcon
            color="secondary"
            fontSize="large"
            sx={{ height: 200, width: 200 }}
          />
          <Typography
            sx={{ fontSize: 14, fontWeight: 800 }}
            color="text.secondary"
            gutterBottom
          >
            Email: andrei.sofrone@aag-software.com
          </Typography>
          <Typography
            sx={{ fontSize: 14, fontWeight: 800 }}
            color="text.secondary"
            gutterBottom
          >
            Phone: +40740750326
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default ContactUs;
