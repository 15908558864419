import react from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SoftwareDev from "../assets/software-development.png";

const Home = () => {
  return (
    <Grid
      xs="12"
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "85vh" }}
    >
      <Grid xs={11} sm={11} md={8} lg={8} xl={8} item>
        <Box
          component="img"
          sx={{
            objectFit: "contain",
            maxHeight: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
            maxWidth: {
              xs: "100%",
              sm: "100%",
              md: "100%",
              lg: "100%",
              xl: "100%",
            },
          }}
          alt="The house from the offer."
          src={SoftwareDev}
        />
      </Grid>
    </Grid>
  );
};

export default Home;
