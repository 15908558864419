import react from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import WebIcon from "@mui/icons-material/Web";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import VisionCodeLogo from "../assets/vision-code.png";
import SoftwareDev from "../assets/software-development.png";
import Coding from "../assets/coding.png";

const Home = () => {
  return (
    <Grid
      sx={{
        flexDirection: {
          xs: "row",
          sm: "row",
          md: "row",
          lg: "row",
          xl: "row",
        },
      }}
      container
    >
      <Grid xs="12" container justifyContent="center" alignItems="center">
        <Grid xs={9} sm={12} md={12} lg={6} xl={6} item>
          <Box
            component="img"
            sx={{
              objectFit: "contain",
              marginTop: "50px",
              maxHeight: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              maxWidth: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "50%",
                xl: "50%",
              },
            }}
            alt="The house from the offer."
            src={Coding}
          />
        </Grid>
        <Grid xs="12" sm="12" md="!2" lg="6" xl="6" item>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 800,
              textAlign: "left",
              margin: "50px",
            }}
          >
            Welcome to AAG Software, the premier destination for top-tier
            software development expertise. Specializing in .NET, JavaScript,
            React, SQL, Cosmos DB, and much more, we offer a flexible,
            project-driven approach to meet your specific needs.
          </Typography>
          {/* <Typography
            sx={{
              fontSize: 20,
              fontWeight: 800,
              textAlign: "left",
              margin: "50px",
            }}
          >
            Our team of seasoned developers is ready to transform your vision
            into reality, delivering innovative solutions with precision and
            efficiency. Partner with us for a seamless software development
            experience.
          </Typography> */}
        </Grid>
      </Grid>
      <Grid xs="12" container>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "black",
                boxShadow: `inset 0px 0px 10px black`,
              },
              border: "solid 3px black",
              backgroundColor: "white",
              marginBottom: "10px",
              margin: {
                xs: "50px",
                sm: "50px",
                md: "50px",
                lg: "50px",
                xl: "80px",
              },
              marginTop: {
                xs: "50px",
                sm: "50px",
                md: "20vh",
                lg: "20vh",
                xl: "20vh",
              },
              padding: "2vh",
            }}
          >
            <CardContent>
              {/* <svg data-testid="WebIcon"></svg> */}
              <WebIcon
                color="secondary"
                fontSize="large"
                sx={{ height: 200, width: 200 }}
              />
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                Frontend development
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button color="secondary" variant="outlined">
                    Learn more (soon)
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card
            sx={{
              "&:hover": {
                color: "black",
                boxShadow: `inset 0px 0px 10px black`,
              },
              border: "solid 3px black",
              backgroundColor: "white",
              margin: "50px",
              marginTop: {
                xs: "50px",
                sm: "50px",
                md: "20vh",
                lg: "20vh",
                xl: "20vh",
              },
              padding: "2vh",
            }}
          >
            <CardContent>
              <MobileFriendlyIcon
                color="secondary"
                fontSize="large"
                sx={{ height: 200, width: 200 }}
              />
              <Typography
                sx={{ fontSize: 14, fontWeight: 800 }}
                color="text.secondary"
                gutterBottom
              >
                Backend applications
              </Typography>
            </CardContent>
            <CardActions>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Button color="secondary" variant="outlined">
                    Learn more (soon)
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
