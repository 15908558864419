import react from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import mainLogo from "../assets/logo-no-back.svg";
import Box from "@mui/material/Box";

const AboutUs = () => {
  return (
    <Grid container>
      <Grid item xs={2} />
      <Grid item xs={8}>
        <Card
          sx={{
            border: "solid 3px black",
            backgroundColor: "white",
            marginTop: {
              xs: "15vh",
              sm: "15vh",
              md: "30vh",
              lg: "30vh",
              xl: "20vh",
            },
            padding: "2vh",
          }}
        >
          {/* <InfoIcon
            color="secondary"
            fontSize="large"
            sx={{ height: 200, width: 200 }}
          /> */}

          <Box
            component="img"
            sx={{
              objectFit: "contain",
              maxHeight: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "100%",
              },
              maxWidth: {
                xs: "100%",
                sm: "100%",
                md: "60%",
                lg: "50%",
                xl: "50%",
              },
            }}
            alt="The house from the offer."
            src={mainLogo}
          />
          <Typography
            sx={{ fontSize: 14, fontWeight: 800 }}
            color="text.secondary"
            gutterBottom
          >
            <h1>Welcome to AAG Software</h1>
            <p>
              At AAG Software, we are not just software developers, we are
              architects of digital transformation. As a leading force in the
              realm of web and mobile app development, we bring ideas to life
              through cutting-edge technology and unparalleled expertise.
            </p>
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default AboutUs;
