import react from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ContactPageIcon from "@mui/icons-material/ContactPage";

const Cookies = () => {
  return (
    <Grid container>
      <Grid item xs={2} />
      <Grid item xs={8} sx={{ textAlign: "left" }}>
        <h1>Cookies Policy</h1>
        <p>
          This website uses cookies to ensure you get the best experience on our
          website.
        </p>
        <h2>What are cookies?</h2>
        <p>
          Cookies are small text files that are stored on your computer or
          mobile device when you visit a website. They allow the website to
          recognize your device and remember your preferences and settings.
        </p>
        <h2>How do we use cookies?</h2>
        <p>We use cookies for a variety of purposes, including:</p>
        <ul>
          <li>Recognizing you when you visit our website</li>
          <li>Remembering your preferences and settings</li>
          <li>Analyzing how you use our website</li>
          <li>Improving the performance of our website</li>
        </ul>

        <h2>Types of cookies we use</h2>
        <p>
          We use both session and persistent cookies on our website. Session
          cookies are temporary cookies that are erased when you close your
          browser, while persistent cookies remain on your device for a set
          period of time or until you delete them manually.
        </p>

        <h2>Managing cookies</h2>
        <p>
          You can control and/or delete cookies as you wish. You can delete all
          cookies that are already on your computer and you can set most
          browsers to prevent them from being placed. If you do this, however,
          you may have to manually adjust some preferences every time you visit
          a site and some services and functionalities may not work.
        </p>

        <h2>Changes to our cookies policy</h2>
        <p>
          We may update our cookies policy from time to time. Any changes we
          make to our cookies policy in the future will be posted on this page.
        </p>

        <h2>Contact us</h2>
        <p>
          If you have any questions about our cookies policy, please contact us.
        </p>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default Cookies;
