import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./home/index";
import AboutUs from "./about-us";
import Contact from "./contact-us";
import AppBar from "./app-bar";
import { ThemeProvider } from "@mui/material/styles";
import AppTheme from "./theme";
import { CssBaseline } from "@mui/material";
import Footer from "./footer/index";
import WhatDoWeDo from "./what-do-we-do";
import Cookies from "./cookies";
import Terms from "./terms-conditions";

const App = () => {
  return (
    <ThemeProvider theme={AppTheme}>
      <CssBaseline enableColorScheme />
      <div className="App">
        <BrowserRouter>
          <AppBar />
          <div
            style={{
              marginTop: "64px",
              minHeight: "85vh",
              marginBottom: "64px",
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/what-do-we-do" element={<WhatDoWeDo />} />
              <Route path="/cookies" element={<Cookies />} />
              <Route path="/terms" element={<Terms />} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

export default App;
