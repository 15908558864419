import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Grid } from "@mui/material";
import mainLogo from "././assets/logo-with-back.png";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: "relative",
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
    backgroundColor: "#ffc93c",
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  backgroundColor: "#ffc93c",
}));

export default function PersistentDrawerRight() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={false}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar style={{ backgroundColor: "#ffc93c" }}>
          <Grid
            container
            style={{
              backgroundColor: "#ffc93c",
              height: "55px",
            }}
          >
            <Grid
              item
              xs={8}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
              direction="row"
              justifyContent="flex-start"
              container
            >
              <img
                src={mainLogo}
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "contain",
                  marginLeft: "16px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/home")}
              />
            </Grid>
            <Grid
              justifyContent={"center"}
              item
              container
              xs={1}
              alignItems="center"
              sx={{
                cursor: "pointer",
                color: "black",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
                "&:hover": {
                  color: "white",
                  filter: "brightness(0)",
                },
              }}
            >
              <Typography
                sx={{ fontSize: 18 }}
                onClick={() => navigate("/home")}
              >
                Home
              </Typography>
            </Grid>
            <Grid
              justifyContent={"center"}
              item
              container
              xs={1}
              alignItems="center"
              sx={{
                cursor: "pointer",
                color: "black",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
                "&:hover": {
                  color: "white",
                  filter: "brightness(0)",
                },
              }}
            >
              <Typography
                sx={{ fontSize: 18 }}
                onClick={() => navigate("/about-us")}
              >
                About us
              </Typography>
            </Grid>
            <Grid
              justifyContent={"center"}
              item
              container
              xs={1}
              alignItems="center"
              sx={{
                cursor: "pointer",
                color: "black",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
                "&:hover": {
                  color: "white",
                  filter: "brightness(0)",
                },
              }}
            >
              <Typography
                sx={{ fontSize: 18 }}
                onClick={() => navigate("/what-do-we-do")}
              >
                Our mission
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={1}
              alignItems="center"
              justifyContent={"center"}
              sx={{
                cursor: "pointer",
                color: "black",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "flex",
                },
                "&:hover": {
                  color: "white",
                  filter: "brightness(0)",
                },
              }}
            >
              <Typography
                sx={{ fontSize: 18 }}
                onClick={() => navigate("/contact")}
              >
                Contact us
              </Typography>
            </Grid>
          </Grid>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{
              zIndex: 1000,
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
                lg: "none",
                xl: "none",
              },
              ...(open && { display: "none" }),
              mr: 2,
            }}
          >
            <MenuIcon style={{ fill: "black" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={(e) => {
          open && setOpen(false);
        }}
      >
        <Drawer
          sx={{
            width: "auto",
            backgroundColor: "#ffbb09",
            zIndex: "9999999999",
            "& .MuiDrawer-paper": {
              border: 0,
            },
          }}
          variant="persistent"
          anchor="top"
          open={open}
        >
          <DrawerHeader
            sx={{ justifyContent: "right", backgroundColor: "#ffbb09" }}
          >
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <CloseIcon /> : <CloseIcon />}
            </IconButton>
          </DrawerHeader>
          <List sx={{ backgroundColor: "#ffbb09" }}>
            <>
              <ListItem
                onClick={() => {
                  navigate("/home");
                  setOpen(false);
                }}
                key="Home"
                disablePadding
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      color: "white",
                      filter: "brightness(0)",
                    },
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 600,
                      fontSize: "larger",
                    }}
                    primary="Home"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/about-us");
                  setOpen(false);
                }}
                key="About us"
                disablePadding
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      color: "white",
                      filter: "brightness(0)",
                    },
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 600,
                      fontSize: "larger",
                    }}
                    primary="About us"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/contact");
                  setOpen(false);
                }}
                key="Contact us"
                disablePadding
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      color: "white",
                      filter: "brightness(0)",
                    },
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 600,
                      fontSize: "larger",
                    }}
                    primary="Contact us"
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  navigate("/what-do-we-do");
                  setOpen(false);
                }}
                key="Our mission"
                disablePadding
              >
                <ListItemButton
                  sx={{
                    "&:hover": {
                      color: "white",
                      filter: "brightness(0)",
                    },
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 600,
                      fontSize: "larger",
                    }}
                    primary="Our mission"
                  />
                </ListItemButton>
              </ListItem>
            </>
          </List>
        </Drawer>
      </ClickAwayListener>
    </Box>
  );
}
