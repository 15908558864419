import react from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const Terms = () => {
  return (
    <Grid container>
      <Grid item xs={2} />
      <Grid item xs={8} sx={{ textAlign: "left" }}>
        <h1>Terms and Conditions</h1>

        <h2>1. Introduction</h2>
        <p>
          Welcome to our website. These terms and conditions govern your use of
          this website; by using our website, you accept these terms and
          conditions in full. If you disagree with these terms and conditions or
          any part of these terms and conditions, you must not use our website.
        </p>

        <h2>2. License to use website</h2>
        <p>
          Unless otherwise stated, we or our licensors own the intellectual
          property rights in the website and material on the website. Subject to
          the license below, all these intellectual property rights are
          reserved.
        </p>
        <p>
          You may view, download for caching purposes only, and print pages from
          the website for your own personal use, subject to the restrictions set
          out below and elsewhere in these terms and conditions.
        </p>

        <h2>3. Acceptable use</h2>
        <p>
          You must not use our website in any way that causes, or may cause,
          damage to the website or impairment of the availability or
          accessibility of the website; or in any way which is unlawful,
          illegal, fraudulent or harmful, or in connection with any unlawful,
          illegal, fraudulent or harmful purpose or activity.
        </p>

        <h2>4. Limitations of liability</h2>
        <p>
          We will not be liable to you (whether under the law of contact, the
          law of torts or otherwise) in relation to the contents of, or use of,
          or otherwise in connection with, this website:
          <ul>
            <li>
              to the extent that the website is provided free-of-charge, for any
              direct loss;
            </li>
            <li>for any indirect, special or consequential loss; or</li>
            <li>
              for any business losses, loss of revenue, income, profits or
              anticipated savings, loss of contracts or business relationships,
              loss of reputation or goodwill, or loss or corruption of
              information or data.
            </li>
          </ul>
        </p>

        <h2>5. Variation</h2>
        <p>
          We may revise these terms and conditions from time-to-time. Revised
          terms and conditions will apply to the use of our website from the
          date of the publication of the revised terms and conditions on our
          website. Please check this page regularly to ensure you are familiar
          with the current version.
        </p>

        <h2>6. Entire agreement</h2>
        <p>
          These terms and conditions constitute the entire agreement between you
          and us in relation to your use of our website, and supersede all
          previous agreements in respect of your use of this website.
        </p>

        <h2>7. Contact information</h2>
        <p>
          If you have any questions about our terms and conditions, please
          contact us.
        </p>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
};

export default Terms;
